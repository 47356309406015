import { createWebHistory, createRouter } from 'vue-router';
import Home from "@/pages/Home.vue";
import Dashboard1 from "@/pages/Dashboard1.vue";
import Dashboard2 from "@/pages/Dashboard2.vue";
import Dashboard3 from "@/pages/Dashboard3.vue";
import Dashboard4 from "@/pages/Dashboard4.vue";
import Dashboard5 from "@/pages/Dashboard5.vue";
import Dashboard6 from "@/pages/Dashboard6.vue";
import Login from "@/pages/Login.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/dashboard1",
    name: "Dashboard1",
    component: Dashboard1
  },
  {
    path: "/dashboard2",
    name: "Dashboard2",
    component: Dashboard2
  },
  {
    path: "/dashboard3",
    name: "Dashboard3",
    component: Dashboard3
  },
  {
    path: "/dashboard4",
    name: "Dashboard4",
    component: Dashboard4
  },
  {
    path: "/dashboard5",
    name: "Dashboard5",
    component: Dashboard5
  },
  {
    path: "/dashboard6",
    name: "Dashboard6",
    component: Dashboard6
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router;